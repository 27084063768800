<template>
    <div class="app-wrapper">
        <el-dropdown trigger="click">
            <span class="user-name">{{userInfo.account}}
            <i class="el-icon-caret-bottom el-icon--right"></i></span>

            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click="goTo('SystemSetIndex')">
                    <router-link :to="{name:'SystemSet',query:{}}">
                        <el-button type="text" size="mini"> 系统设置</el-button>
                    </router-link>
                </el-dropdown-item>
                <el-dropdown-item @click="goTo('SystemSetIndex')">
                    <router-link :to="{name:'SystemSet',query:{}}">
                        <el-button type="text" size="mini"> 日志管理</el-button>
                    </router-link>
                </el-dropdown-item>
<!--                <el-dropdown-item @click="goTo('BranchList')">-->
<!--                    <router-link :to="{name:'BranchList',query:{}}">-->
<!--                        <el-button type="text" size="mini"> 权限设置</el-button>-->
<!--                    </router-link>-->
<!--                </el-dropdown-item>-->
                <el-dropdown-item >
                    <el-button type="text" size="mini" @click="clearData()"> 退出登录</el-button>
<!--                    <router-link :to="{name:'Login',query:{}}">-->
<!--                        <el-button type="text" size="mini"> 退出</el-button>-->
<!--                    </router-link>-->
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
    import {mapGetters,mapActions} from 'vuex'
    import {resetRouter} from '@/router'
  export default {
    data(){
      return{
      }
    },
    components: {

    },
    computed: {
      ...mapGetters(['userInfo']),
      data(){
        return this.$route.matched
      }
    },
    created() {
    },
    methods: {
      ...mapActions(['setMenu','setUserInfo','setButton']),
      goTo(name){
        this.$router.push({name})
      },
      clearData(){
        this.setUserInfo({})
        this.setMenu([])
        this.setButton([])
        // this.$router.replace({path:'/login'})
      }
    }
  }
</script>

<style lang="scss" scoped>
    .el-dropdown{

        .el-icon--right{
            font-size: 12px;
            margin-left: 0;
        }
    }
    .user-name{
        color:#fff;
        margin-right: 10px;
    }
</style>
