<template>
    <div class="sidebar-container">

        <el-menu
                :default-active="activeIndex.route_name"
                class="el-menu-demo"
                mode="horizontal"
                background-color="#212634"
                text-color="#fff"
                active-text-color="#ffd04b"
                @select="handleSelect">
            <el-menu-item :index="item.route_name" v-for="(item,index) in menuList" :key="index" v-if="item.is_show==1">{{item.menu_name}}</el-menu-item>

        </el-menu>
    </div>
</template>

<script>

import {mapGetters,mapActions} from  'vuex'
import {mixin} from '../mixin'
  export default {
    mixins: [mixin],
    name:'TopBar',
    props:{
      matched: {
        default: () => {
          return []
        }
      },

    },
    data(){
      return{
        menu:[],
      }
    },
    components: {


    },
    watch: {
      '$route'(to, from) {
      }
    },
    computed: {
      ...mapGetters(['menuList']),

    },
    methods: {
      handleSelect(name){
        this.$router.push({name})
      }
    },
    created() {
    },
    mounted() {
    }

  }
</script>

<style lang="scss" scoped>
    .sidebar-container {
        transition: width 0.28s;
        background-color: #fff;
        overflow: hidden;
        height:60px;
        .el-menu-vertical-demo{
            border-right-color:#fff;
        }
        .el-menu--horizontal>.el-menu-item.is-active{
            border-bottom:none  !important;
            color:#fff !important;
            background-color: #000 !important;
            border-bottom-color:#000 !important;
        }
    }
</style>
