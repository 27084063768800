<template>
    <div>
        <div class="fixed-icon" @click="drawer=true" :class="{'move-fixed':drawer}">
            <i class="el-icon-s-order"></i>
            待办事项
        </div>
        <el-drawer
                title="待办事项"
                :visible.sync="drawer"
                :direction="direction"
                :before-close="handleClose"
                custom-class="dialog-drawer"
                :modal="false"
        @open="openDialog">
            <div class="drawer-box">
                <ul class="schedule-list">
                    <li v-for="(item,index) in list" :class="{'special-li':index==(list.length-1)}"

                    >
                        <div class="list-head">
                            <i class="list-circle"></i>
                            <span>{{item.title}}</span>

                        </div>
                        <div class="list-content">
                            <p v-for="sub in item.children" @click="handleClick(sub.route_name,sub.type)">
                                {{sub.title}}
                                <span class="clred cl" :class="{'clgray':sub.num==0}">
                                （ {{sub.num}}）
                            </span>
                            </p>

                        </div>
                    </li>
                </ul>

            </div>


        </el-drawer>
    </div>
</template>

<script>
  export default {
    name: "index",
    props:['list'],
    data(){
      return{
        drawer:false,
        direction:'rtl',

      }
    },
    methods:{
      handleClose(){
        this.drawer=false
      },
      handleClick(name,type){
        this.$emit('handleClick',{name,type})
        this.handleClose()
      },
      openDialog(){
        this.$emit('openDialog')
      }
    }
  }
</script>
<style scoped>
    /deep/.dialog-drawer{
        background: #F5F7FA;
        width: 600px !important;
    }
</style>
<style scoped lang="scss">
    .drawer-box{
        overflow: auto;
        height: calc(100vh - 90px);
    }
    .fixed-icon{
        position: fixed;
        right:0;
        top:50%;
        z-index: 2008;
        writing-mode: vertical-lr;
        background-color: #fff;
        padding: 10px 5px;
        border: 1px solid #ccc;
        cursor:pointer;
        font-size: 14px;
        text-align: center;
        transition: all 0.3s;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        &.move-fixed{
            right:600px;
        }
        .el-icon-s-order{
            color:#4d68ff;
            padding-bottom: 5px;
        }
    }

    .schedule-list{
        display: flex;
        flex-wrap: wrap;
        font-size: 15px;
        padding: 0 20px;

        li{
            width: calc(calc(100% / 2) - 16px);
            margin: 8px;
            background: #fff;
            box-sizing: border-box;
            border: 1px solid rgba(0, 0, 0, 0.05);
            cursor:pointer;
            transition: 0.5s;
            &.special-li{
                width: calc(calc(100%));
                .list-content{
                    display: flex;
                    flex-wrap: wrap;
                    p{
                        width: calc(calc(100% / 2) - 10px);
                        margin-right: 5px;
                        &:hover{
                            color: #4D68FF;
                        }

                    }
                }
            }
            &:hover{
                border:1px solid #ccc;
                box-shadow: 4px 4px 10px #cccccc;

            }
        }
        .list-content{
            padding:24px;
            p{
                padding-bottom: 10px;
                &:last-child{
                    padding-bottom: 0;
                }
                &:hover{
                    color: #4D68FF;
                }
            }
        }
        .list-head{
            font-weight: bold;
            padding:10px;

            background-color: rgba(77,104,255,0.05);
            .list-circle{
                display: inline-block;
                width: 4px;
                height: 4px;
                background: #4D68FF;
                border-radius: 2px;
                vertical-align: middle;
            }
            span{
                font-size: 16px;
                padding-left: 10px;
            }

        }
    }
    .clred {
        &.clgray{
            color:gray;
        }
    }

</style>
